html {
  height: 100%;
  width: 100vw;
  scrollbar-width: none;
}
body,
#root {
  height: 100%;
  width: 100%;
}
#inner-root {
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: #dedede;
  position: relative;
  min-height: 100vh;
  /* overflow-x: hidden; */
  z-index: 0;
}
.test-class {
  border: 2px solid yellow;
}

/* -- header -- */
header {
  position: sticky;
  top: 0;
  height: 12vh;
  width: 98%;
  background-color: #777777;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.2)
  );
  animation: bgColorAnimated alternate 16s ease-in infinite;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0 1rem 2%;
  z-index: 1000;
}
@keyframes bgColorAnimated {
  0% {
    background-color: #777777;
  }
  50% {
    background-color: #002255;
  }
  100% {
    background-color: #777777;
  }
}
header div {
  color: #ffffff;
  font-weight: bold;
  width: 8vw;
  /* padding: 1rem 0.5vw 1rem 0.5vw; */
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  user-select: none;
  text-align: center;
  position: relative;
  background-color: transparent;
  transition: 0.2s ease-in;
}
header div:last-child {
  border-right: none;
}
header div:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.selected-tab::after {
  content: "";
  position: absolute;
  bottom: 0.25rem;
  left: 3vw;
  width: 2vw;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1px;
}
.link {
  color: inherit;
  text-decoration: none;
  display: block;
  padding: 1rem 0.5vw 1rem 0.5vw;
}
@media screen and (max-width: 1080px) {
  header {
    justify-content: center;
  }
  header div {
    width: 25vw;
  }
  .selected-tab::after {
    left: 11vw;
    width: 4vw;
  }
}

/* -- footer -- */
footer {
  position: absolute;
  bottom: 0;
  height: 5vh;
  width: 100%;
  background-color: #002255;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.8)
  );
  display: flex;
  justify-content: center;
  align-items: center;
}
footer div {
  color: #ffffff;
  padding-left: 1rem;
  padding-right: 1rem;
}
footer a {
  text-decoration: none;
  color: #7777ff;
}
footer a:hover {
  color: #777777;
}

/* -- square animations -- */
.square-animated {
  position: fixed;
  bottom: -20vh;
  background-color: rgba(0, 0, 32, 0.2);
  animation-name: rotate, moveup;
  animation-duration: 2s, 12s;
  animation-timing-function: linear, linear;
  animation-delay: 0ms, 0ms;
  animation-iteration-count: infinite;
  z-index: -1;
}
.square-sm {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.2rem;
}
.square-md {
  height: 4rem;
  width: 4rem;
  border-radius: 0.5rem;
}
.square-lg {
  height: 6.4rem;
  width: 6.4rem;
  border-radius: 0.7rem;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes moveup {
  from {
    bottom: -20vh;
  }
  to {
    bottom: 120vh;
  }
}
.square-animated:nth-of-type(1) {
  left: 8%;
  animation-duration: 3.2s, 15s;
  animation-delay: 4s, 12s;
}
.square-animated:nth-of-type(2) {
  left: 22%;
  animation-duration: 2s, 12s;
  animation-delay: 0s, 0s;
}
.square-animated:nth-of-type(3) {
  left: 23%;
  animation-duration: 6s, 15s;
  animation-delay: 8s, 24s;
}
.square-animated:nth-of-type(4) {
  left: 40%;
  animation-duration: 3.2s, 18s;
  animation-delay: 0s, 0s;
}
.square-animated:nth-of-type(5) {
  left: 44%;
  animation-duration: 1.8s, 9.6s;
  animation-delay: 0.5s, 1.5s;
}
.square-animated:nth-of-type(6) {
  left: 54%;
  animation-duration: 7.2s, 18s;
  animation-delay: 2s, 6s;
}
.square-animated:nth-of-type(7) {
  left: 56%;
  animation-duration: 18s, 42s;
  animation-delay: 0s, 0s;
}
.square-animated:nth-of-type(8) {
  left: 68%;
  animation-duration: 1s, 9.6s;
  animation-delay: 1s, 3s;
}
.square-animated:nth-of-type(9) {
  left: 74%;
  animation-duration: 8s, 12s;
  animation-delay: 2s, 6s;
}
.square-animated:nth-of-type(10) {
  left: 88%;
  animation-duration: 3.2s, 24s;
  animation-delay: 3.2s, 9.6s;
}
.square-animated:nth-of-type(11) {
  left: 90%;
  animation-duration: 2s, 12s;
  animation-delay: 0s, 0s;
}

/* -- content containers -- */
.content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5vh;
}
.horizontal-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  flex-wrap: wrap;
}
.vertical-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

/* -- cards -- */
.card {
  background-color: rgba(0, 10, 20, 0.75);
  color: #ffffff;
  padding: 1rem;
}
.card p {
  line-height: 1.5rem;
}
.card ul {
  padding-left: 1rem;
  line-height: 1.5rem;
}
.card > ul {
  padding-left: 1rem;
  line-height: 1.8rem;
}
.card-2 {
  width: 40vw;
}
.card-3 {
  width: 25vw;
}
.card-img-3 {
  width: calc(25vw + 2rem);
  height: auto;
}
.filler {
  background-color: transparent;
}
.scroll-fade {
  opacity: 0;
  transition: opacity 2.5s;
}
.card h2 {
  padding-left: 0;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.card h4 {
  margin-bottom: 0;
}
.card h6 {
  margin: 0;
  font-size: .75em;
  color: #9f9f9f
}
@media screen and (max-width: 1020px) {
  .card-img-3,
  .card-3 {
    width: 40vw;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 720px) {
  .card {
    width: calc(90% - 2rem);
    margin-bottom: 1rem;
  }
  [class*="card-img-"] {
    width: 90%;
    margin-bottom: 1rem;
  }
  .filler {
    display: none;
  }
}

/* -- other content -- */
h1 {
  width: 100%;
  margin-top: 30vh;
  margin-bottom: 50vh;
  font-size: 6.4rem;
  font-weight: bold;
  text-align: center;
  position: relative;
}
h1::after {
  content: "\00bb";
  transform: rotate(90deg);
  position: absolute;
  bottom: -20vh;
  left: 50vw;
  animation: small-slide-down 2s ease infinite;
}
@keyframes small-slide-down {
  0% {
    bottom: -20vh;
  }
  100% {
    bottom: -22vh;
  }
}
h2 {
  width: calc(93.33% + 1.33rem);
  padding-left: calc(6.67% - 1.33rem);
  margin-top: 2rem;
}
@media screen and (max-width: 720px) {
  h2 {
    padding-left: 5vw;
    width: 95vw;
  }
}

/* -- table -- */
.my-table {
  padding-left: calc(6.25vw - 2.5rem);
  padding-right: calc(6.25vw - 2.5rem);
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 100%;
  line-height: 2.5rem;
  border-spacing: 1rem;
  font-size: 1.5rem;
}
.my-table td,
.my-table th {
  background-color: rgba(0, 10, 20, 0.75);
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 1rem;
  text-align: center;
  padding: 1.5rem;
}
.my-table th {
  background-color: rgba(0, 10, 20, 0.75);
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );
  color: #ffffff;
}
@media screen and (max-width: 1080px) {
  .content-container h1 {
    font-size: 4rem;
  }
  .vertical-container h2 {
    align-self: center;
  }
  .my-table {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    line-height: 2rem;
    border-spacing: 0.5rem;
    font-size: 1rem;
  }
}

/* -- timeline -- */
.timeline {
  position: relative;
}
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: rgb(0, 0, 0);
  top: 2rem;
  bottom: 7.5vh;
  left: 50%;
  margin-left: -3px;
}
.timeline_bubble_left {
  position: relative;
}
.timeline_bubble_left::before {
  content: " ";
  height: 0.5rem;
  position: absolute;
  background-color: rgba(0, 10, 20, 0.75);
  top: calc(50% - 0.25rem);
  width: calc(3.33vw - 0.5rem);
  z-index: 0;
  right: calc(-1 * 3.33vw + 0.5rem);
}
.timeline_bubble_right {
  position: relative;
}
.timeline_bubble_right::before {
  content: " ";
  height: 0.5rem;
  position: absolute;
  background-color: rgba(0, 10, 20, 0.75);
  top: calc(50% - 0.25rem);
  width: calc(3.33vw - 0.5rem);
  z-index: 0;
  left: calc(-1 * 3.33vw + 0.5rem);
}
@media screen and (max-width: 720px) {
  .timeline::after {
    left: 0.5rem;
    bottom: calc(7.5vh + 1rem);
  }
  .timeline_bubble_left::before,
  .timeline_bubble_right::before {
    right: auto;
    left: calc(0.5rem - 5vw);
    width: calc(5vw - 0.5rem);
  }
}

.link-light {
  color: #7777ff;
}
.link-light:hover {
  color: #777777;
}